import React, { useState } from 'react';
import { FilterModal } from '../FilterModal';
import { ModalConfig } from '../types';
import { SortingFilter } from '../../SortingFilter';
import useScreenSize from '../../../../../hooks/useScreenSize';

export const Sorting = () => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);
  const { isSmall } = useScreenSize();

  const hideModal = () => setModalConfig(null);

  const handlePopoverClick = (value: ModalConfig) => {
    if (!isSmall) return undefined;

    return () => setModalConfig(value);
  };

  return (
    <>
      <SortingFilter
        onClick={handlePopoverClick(ModalConfig.Sorting)}
        submitOnChange
      />

      <FilterModal
        hide={hideModal}
        isOpen={!!modalConfig}
        modalConfig={modalConfig}
      />
    </>
  );
};

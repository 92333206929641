import React, { useEffect, useMemo, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchVippedItemsUrl } from '../../utils/helpers';
import {
  searchItemsQuery,
  searchVippedItemsQuery,
  searchVippedResidencesItemsQuery
} from '../../queries';
import { setChosenLocationNames, setLocationIds } from '../../actions';
import { SectionPlaceholder } from '../Placeholders/SectionPlaceholder';
import { ItemCardPlaceholder } from '../Placeholders/ItemCardPlaceholder';
import { NewSearch } from '../NewSearch';
import { withEndlessScroll } from '../HOC/endlessScroll';
import { FallbackView } from '../FallbackView';
import { ItemCard } from '../ui/ItemCard';
import { useBookmarks } from '../../hooks/useBookmarks';
import { GeneralAdsPageProps } from './types';
import { getChosenLocationNames } from '../../utils/helpers/getChosenLocationNames';
import { HomeLayoutWithBanner } from '../HomeLayoutWithBanner';
import { NewSection } from '../NewSection';
import { CardsLayout } from '../ui/CardsLayout';
import { capitalizeFirstLetter } from '../../utils/helpers/common';
import { AdsTitle, AdsTitleCount, SearchPageTitle } from './styled';
import { Sorting } from '../NewSearch/NewSearchBar/SearchPageFilters/Sorting';
import {
  ANY_CITY_ID,
  SEARCH_RESULT_ROUTES
} from '../../utils/helpers/constants';
import { selectSearchFormCurrentCity } from '../../store/selectors';
import { useCityAndLocationsQuery } from '../useCityAndLocationsQuery';
import useScreenSize from '../../hooks/useScreenSize';
import { useClient } from '../../hooks/useClient';

const Banner = dynamic(() => import('../Banner'), { ssr: false });
const searchListRef = React.createRef();
const EndlessSearchItems = withEndlessScroll(CardsLayout);

export const GeneralAdsPage = forwardRef(
  (
    {
      transformedObj,
      setErrorUI,
      h1,
      totalAdsCount,
      categories
    }: GeneralAdsPageProps,
    ref
  ) => {
    const { isSmall, isLarge } = useScreenSize();
    const router = useRouter();
    const { t } = useTranslation();
    const params = useSearchParams();
    const dispatch = useDispatch();
    const isClient = useClient();
    const { isBookmarked } = useBookmarks();
    const currentCity: string = useSelector(selectSearchFormCurrentCity);

    const isItemsAllPage = router.pathname === SEARCH_RESULT_ROUTES.ITEMS_ALL;

    const searchVippedItemsUrl = getSearchVippedItemsUrl(transformedObj.filter);

    const locationIds = useMemo(
      () => params.getAll('location_ids[]'),
      [params.getAll]
    );

    const cityId = currentCity || ANY_CITY_ID;
    const { data } = useCityAndLocationsQuery(cityId);
    const locations = data?.locations || [];

    useEffect(() => {
      if (locationIds.length) {
        const locationNames = getChosenLocationNames(locations, locationIds);
        dispatch(setChosenLocationNames(locationNames));
        dispatch(setLocationIds(locationIds));
      }
    }, [locationIds, data]);

    useEffect(() => {
      router.beforePopState(({ options }) => {
        // eslint-disable-next-line no-param-reassign
        options.scroll = false;

        return true;
      });
    }, []);

    const searchItemsQueryVariables = {
      first: 16,
      filter: transformedObj.filter,
      sort: transformedObj.sorting || 'BUMPED_AT_DESC'
    };
    const searchVippedQueryVariables = {
      filter: { ...transformedObj.filter, scope: 'VIPPED_PURE' },
      limit: 4,
      sort: 'RANDOM'
    };
    const searchVippedResidencesQueryVariables = {
      filter: { ...transformedObj.filter, scope: 'VIPPED_APARTMENTS' },
      limit: 4,
      sort: 'RANDOM'
    };

    /*
     * Load  data via hooks
     * */
    const [
      {
        loading: searchItemsQueryLoading,
        data: searchItemsQueryData,
        error: searchItemsError,
        fetchMore
      },
      {
        loading: searchVippedItemsQueryLoading,
        data: searchVippedItemsQueryData,
        error: searchVippedItemsQueryError
      },
      {
        loading: searchVippedResidencesItemsQueryLoading,
        data: searchVippedResidencesItemsQueryData,
        error: searchVippedResidencesItemsQueryError
      }
    ] = (() => {
      const searchQuery = useQuery(
        searchItemsQuery(searchItemsQueryVariables),
        {
          variables: searchItemsQueryVariables,
          ssr: false,
          notifyOnNetworkStatusChange: true
        }
      );
      const searchVippedQuery = useQuery(
        searchVippedItemsQuery(searchVippedQueryVariables),
        {
          variables: searchVippedQueryVariables,
          ssr: false,
          notifyOnNetworkStatusChange: true,
          skip: isItemsAllPage
        }
      );
      const searchVippedResidencesQuery = useQuery(
        searchVippedResidencesItemsQuery(searchVippedResidencesQueryVariables),
        {
          variables: searchVippedResidencesQueryVariables,
          ssr: false,
          notifyOnNetworkStatusChange: true,
          skip: isItemsAllPage
        }
      );

      return [searchQuery, searchVippedQuery, searchVippedResidencesQuery];
    })();

    /*
     * searchItems
     * */
    const itemsList =
      searchItemsQueryData?.itemsConnection?.edges.map((item, currIndex) => {
        const count = (() => {
          switch (true) {
            case isSmall:
              return 5;
            case isLarge:
              return 11;
            default:
              return 8;
          }
        })();
        const isBannerPosition =
          isSmall &&
          (currIndex === count ||
            (currIndex > count && (currIndex - count) % 30 === 0));

        return (
          <React.Fragment key={item.node.id}>
            <ItemCard {...item.node} inBookmarks={isBookmarked(item.node.id)} />
            {isSmall
              ? isBannerPosition && (
                  <Banner
                    tagName={'p'}
                    containerClassName="lotriver-bottom-banner lotriver-search-banner content-banner"
                    containerId={`js-lotriver-bottom-banner-${currIndex}`}
                    sid={'220686'}
                    bn={'6'}
                    arCn={Number(currIndex)}
                  />
                )
              : currIndex === count && (
                  <Banner
                    tagName={'p'}
                    containerClassName={'lotriver-search-banner content-banner'}
                    containerId={`js-lotriver-search-banner`}
                    sid={'220686'}
                    bn={'7'}
                    arCn={Number(currIndex)}
                  />
                )}
          </React.Fragment>
        );
      }) || [];

    /*
     * vipSearchItems
     * */

    const getVipListLimit = () => {
      switch (true) {
        case isLarge:
          return 4;
        case isSmall:
          return 2;
        default:
          return 3;
      }
    };

    const itemsVipList =
      (searchVippedItemsQueryData?.items &&
        searchVippedItemsQueryData.items
          .slice(0, getVipListLimit())
          .map(item => {
            return (
              <ItemCard
                key={item.id}
                {...item}
                inBookmarks={isBookmarked(item.id)}
              />
            );
          })) ||
      [];

    /*
     * vipResidencesSearchItems
     * */
    const itemsVipResidencesList =
      (searchVippedResidencesItemsQueryData?.items &&
        searchVippedResidencesItemsQueryData.items.map(item => {
          return (
            <ItemCard
              key={item.id}
              {...item}
              inBookmarks={isBookmarked(item.id)}
            />
          );
        })) ||
      [];

    useEffect(() => {
      if (
        searchVippedItemsQueryError ||
        searchVippedResidencesItemsQueryError ||
        searchItemsError
      ) {
        setErrorUI(true);
      }
    }, [
      searchVippedItemsQueryError,
      searchVippedResidencesItemsQueryError,
      searchItemsError
    ]);

    const SkeletonLoaders = ({ loading = true }: { loading?: boolean }) => {
      if (!loading) return null;
      return (
        <SectionPlaceholder isCardsLayout>
          {Array.from({ length: 4 }, (_, index) => (
            <ItemCardPlaceholder key={index} isCardsLayout />
          ))}
        </SectionPlaceholder>
      );
    };

    const Error = () => (
      <FallbackView
        width={317}
        height={230}
        text={t('search.no_results.text')}
        icon="/static/images/new_ad/icons/icon-not-found.png"
      />
    );

    const AdsSectionTitle = () => (
      <AdsTitle tag="h3">
        {t(isItemsAllPage ? 'search.items_all_ads' : 'search.ads')}
        <AdsTitleCount tag="body_1">
          ({searchItemsQueryData?.itemsConnection.totalCount})
        </AdsTitleCount>
      </AdsTitle>
    );

    return (
      <HomeLayoutWithBanner>
        <NewSearch
          formElToAssign={ref}
          isLoading={
            searchVippedItemsQueryLoading ||
            searchVippedResidencesItemsQueryLoading
          }
          isHomePage={false}
          categories={categories}
        />
        {totalAdsCount === 0 ? (
          <Error />
        ) : (
          <>
            {h1 && (
              <SearchPageTitle tag="h2" data-cy="search-page-title">
                {capitalizeFirstLetter(h1)}
              </SearchPageTitle>
            )}

            {(!itemsVipList.length &&
              !searchVippedItemsQueryLoading &&
              isClient) || (
              <NewSection
                id="search-page-vipped"
                title={t('sections.vipped.title')}
                url={searchVippedItemsUrl}
                urlTitle={t('sections.residences.show_all')}
                headlineType="h3"
                cypress="vipped-section-title"
              >
                {!searchVippedItemsQueryLoading ? (
                  !!itemsVipList.length && (
                    <CardsLayout>{itemsVipList}</CardsLayout>
                  )
                ) : (
                  <SkeletonLoaders />
                )}
              </NewSection>
            )}
            {!searchVippedResidencesItemsQueryLoading ? (
              !!itemsVipResidencesList.length && (
                <NewSection
                  id="search-page-vipped-residences"
                  title={t('sections.vipped_residences.title')}
                  headlineType="h3"
                >
                  <CardsLayout>{itemsVipResidencesList}</CardsLayout>
                </NewSection>
              )
            ) : (
              <SkeletonLoaders
                loading={searchVippedResidencesItemsQueryLoading}
              />
            )}

            {!(searchItemsQueryLoading && !itemsList.length) ? (
              <NewSection
                id="search-page-regular-items"
                title={<AdsSectionTitle />}
                headlineType="h3"
                cypress="search-page-regular-items-title"
                rightBlock={<Sorting />}
              >
                <EndlessSearchItems
                  items={itemsList || []}
                  loading={searchItemsQueryLoading}
                  ref={searchListRef}
                  isStopLoad={
                    searchItemsQueryData &&
                    !searchItemsQueryData.itemsConnection.pageInfo.hasNextPage
                  }
                  onLoadMore={() => {
                    const moreSearchItemsQueryVariables = {
                      first: 16,
                      filter: transformedObj.filter,
                      sort: transformedObj.sorting || 'BUMPED_AT_DESC',
                      cursor:
                        searchItemsQueryData.itemsConnection.pageInfo.endCursor
                    };
                    fetchMore({
                      variables: moreSearchItemsQueryVariables
                    });
                  }}
                >
                  {itemsList}
                </EndlessSearchItems>
              </NewSection>
            ) : (
              <SkeletonLoaders />
            )}
          </>
        )}
      </HomeLayoutWithBanner>
    );
  }
);

GeneralAdsPage.displayName = 'GeneralAdsPage';

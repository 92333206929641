import styled from 'styled-components';
import { Typography } from '../ui/Typography';

export const SearchPageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.primary};
  padding: 0 15px;
  margin: 0;
  text-wrap: balance;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 0 40px;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding: 5px 20px 15px;
  }
`;

export const AdsTitle = styled(Typography)`
  margin: 0;
  display: flex;
  gap: 5px;
`;

export const AdsTitleCount = styled(Typography)`
  margin: 0;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

import styled, { css } from 'styled-components';
import { FlexCenterAligned } from '../../../../styled/common';

export const sortingContainerStyles = css`
  label {
    cursor: pointer;
    padding: 0;
    height: 45px;

    &::after {
      display: none;
    }
  }
`;

export const popoverFilterStyles = css`
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 10px 15px;
    height: 44px;
    border-radius: 14px;
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
  }
`;

export const SelectedValueContainer = styled(FlexCenterAligned)`
  > svg {
    stroke-width: 0.6;
  }

  font-size: 12px;
  gap: 2px;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    gap: 5px;
  }
`;

import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { PopoverFilter } from '../../../ui/PopoverFilter';
import { NewSorting } from '../SearchPageFilters/NewSorting';
import { selectSearchFormCurrentSortType } from '../../../../store/selectors';
import { SORTING_VARIANTS } from '../../../../utils/helpers/constants';
import {
  popoverFilterStyles,
  SelectedValueContainer,
  sortingContainerStyles
} from './styled';
import useScreenSize from '../../../../hooks/useScreenSize';
import SortIcon from '../../../../public/static/images/new_search/icons/icon-sort.svg';
import Icon from '../../../Icon';
import { PopoverHorizontalAlignmentEnum } from '../../../ui/PopoverFilter/types';

interface SortingFilterProps {
  onClick?: () => void;
  submitOnChange?: boolean;
}

const DATE_SORTING = '1';

export const SortingFilter = ({
  onClick,
  submitOnChange = false
}: SortingFilterProps) => {
  const { t } = useTranslation();
  const { isSmall } = useScreenSize();
  const popoverRef = useRef<{ closePopover: () => void }>(null);
  const currentSort = useSelector(selectSearchFormCurrentSortType);
  const currentSortValue = currentSort || DATE_SORTING;

  const value = SORTING_VARIANTS.find(
    variant => variant.id === currentSortValue
  );

  const icon = (
    <Icon IconComponent={SortIcon} width={20} height={20} indent={false} />
  );

  const selectedValue = (
    <SelectedValueContainer>
      {icon} {t(value?.name || '')}
    </SelectedValueContainer>
  );

  const sortingPlaceholder = `${t('search.buttons.sort')}`;

  const handleClose = () => {
    if (submitOnChange && popoverRef.current) {
      popoverRef.current.closePopover();
    }
  };

  return (
    <PopoverFilter
      ref={popoverRef}
      placeholder={sortingPlaceholder}
      value={selectedValue}
      cypress="new-search-sorting-modal"
      stat="new-search-sorting-modal"
      horizontalAlign={PopoverHorizontalAlignmentEnum.right}
      width={251}
      onClick={onClick}
      showIcon={!isSmall}
      styles={popoverFilterStyles}
    >
      <NewSorting hide={handleClose} styles={sortingContainerStyles} />
    </PopoverFilter>
  );
};

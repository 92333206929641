import { ApolloClient } from '@apollo/client';
import { categoriesQuery, staticFooterDataQuery } from '../../queries';
import { CategoriesQuery, StaticFooterDataQuery } from '../../graphql/graphql';
import { Cities } from './constants';

interface StaticData {
  citiesUnPopularList: StaticFooterDataQuery['cities'];
  regions: { [cityId: string]: StaticFooterDataQuery['regions'] };
  categories: CategoriesQuery['categories'];
  error?: unknown;
}
export const getStaticData = async (
  apolloClient: ApolloClient<unknown>,
  withCategories = false
): Promise<StaticData> => {
  const locationVariables = { cityId: Cities.Baku.id };
  let categories = [];
  try {
    const { data } = await apolloClient.query<StaticFooterDataQuery>({
      query: staticFooterDataQuery
    });

    if (withCategories) {
      const { data: categoriesData } =
        await apolloClient.query<CategoriesQuery>({
          query: categoriesQuery
        });
      categories = categoriesData?.categories || [];
    }

    return {
      citiesUnPopularList: data?.cities || [],
      regions: { [locationVariables.cityId]: data?.regions || [] },
      categories
    };
  } catch (e) {
    console.log(e);
    return {
      citiesUnPopularList: [],
      regions: { [locationVariables.cityId]: [] },
      error: JSON.stringify(e),
      categories: []
    };
  }
};
